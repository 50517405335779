<script lang="ts" setup>
defineProps<{
	small?: boolean;
}>();

const { t } = useT();
const isGuest = useIsGuest();
const { data: appInit } = useAppInitData();

const { BALLS, counterBalls, collectedBalls } = useBingoData({
	immediate: !isGuest.value && appInit.value?.bingo?.isActive
});

const ballsArray = computed(() =>
	Array.from({ length: BALLS }, (_, index) => (index < collectedBalls.value ? getRandom({ min: 1, max: 90 }) : null))
);

const shuffle = (array: { light: string; dark: string }[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

const shuffledColors = computed(() => shuffle(BINGO_COLORS));
</script>

<template>
	<div :class="['progress-wrapper', { small: small }]">
		<div class="progress">
			<template v-for="(ball, index) in ballsArray">
				<MBingoBall
					v-if="ball"
					:id="index"
					:key="`ball-${index}`"
					:light="shuffledColors[index].light"
					:dark="shuffledColors[index].dark"
					:number="ball"
				/>
				<ASvg v-else :key="`hole-${index}`" class="hole" name="24/bingo-empty-ball" filled />
			</template>
		</div>
		<AText class="text" :size="16" :modifiers="['bold', 'right', 'wide']" as="div">
			<span class="text-primary-200">{{ counterBalls }}</span> {{ t("bingo.progress.txt") }}
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.progress-wrapper {
	width: 100%;
}
.progress {
	margin-bottom: 8px;
	border-radius: 100px;
	background: var(--neutral-950);
	padding: 6px 8px;
	display: flex;
	align-items: center;
	gap: 8px;

	& > * {
		position: relative;
		width: 24px;
		max-width: 24px;
		height: 24px;
		max-height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;

		:deep(svg) {
			margin: 0;
			width: 100%;
			height: 100%;
		}
	}

	.hole {
		opacity: 0.5;
	}
}

.small {
	.progress {
		margin-bottom: 4px;
		padding: 2px;
		gap: 1px;

		& > * {
			width: 12px;
			min-width: 12px;
			height: 12px;
		}

		:deep(.number) {
			font-size: 5px;
			font-weight: 500;
			margin: 0 auto auto -1px;
			line-height: 12px;
		}
	}
	.text {
		font-size: 12px;
	}
}
</style>
